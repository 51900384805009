import React from "react";
import '../App.css'

function FooterSection () {
    return(
        <footer id="contacto" data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="800"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false">
                    <h1 className="tcontacto">CONTACTO</h1>
                    <div className="contactob">
                        <div className="horariotext">
                        <div className="horario">
                            <h3>HORARIO</h3>
                            <p>MIÉRCOLES A LUNES DE 12:00PM A 11:00PM (CERRAMOS LOS MARTES)</p>
                        </div>
                        <p>BELISARIO DOMÍNGUEZ 1502C, CENTRO HISTÓRICO, 82000 MAZATLÁN,SIN.</p>
                        <div className="reservacion">
                            <h3>RESERVACIONES</h3>
                            <p>(669) 244 46 17</p>
                        </div>
                        <div className="redes">
                            <a
                            href="https://www.facebook.com/naokitchenbar/?locale=es_LA"
                            target="_blank"
                            >
                            <picture>
                                <img
                                className="red"
                                src="assets/images/facebook.svg"
                                alt="facebook.svg"
                                />
                            </picture>
                            </a>
                            <a href="https://www.instagram.com/naokitchenbar/" target="_blank">
                            <picture>
                                <img
                                className="red"
                                src="assets/images/ins.ico.svg"
                                alt="ins.ico.svg"
                                />
                            </picture>
                            </a>
                        </div>
                        </div>
                        <picture>
                        <img
                            className="platof"
                            src="assets/images/contacto_plato.png"
                            alt="contacto_plato.png"
                        />
                        </picture>
                    </div>
                    <div className="naof">
                        <h1>NAO KITCHEN,2024</h1>
                    </div>
        </footer>
    )
}

export default FooterSection;