import React from 'react'
import '../App.css'
function NavbarSite() {
    return(
<header>
  <div className="navbar" data-aos="fade-down"
        data-aos-anchor-placement="center-bottom"data-aos-duration="800"
        data-aos-mirror="true">
    <a href="index.html" title="index.html">
      <img
        className="logo"
        src="assets/images/logo_nao.png"
        alt="logo_nao.png"
        title="logo_nao.png"
      />
    </a>
    <div className="navtext">
      <a href="#menu">
        <h3>MENÚ</h3>
      </a>
      <a href="#contacto">
        <h3>CONTACTO</h3>
      </a>
    </div>
  </div>
  <div className="texto__plato" data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="800"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="false">
    <h2>
      EL AUTÉNTICO <br />
      SABOR ORIENTAL
    </h2>
  </div>
</header>

    );
}

export default NavbarSite;