import React from 'react'
import '../App.css'

function Circulodiv() {
    return (
        <div id="menu" className="circulo" data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="800"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false">
        <picture className="cmenu">
            <img src="assets/images/Ellipse 1.png" alt="elipse1.png" />
        </picture>
        <h1 className="tituloc">NUESTRO MENÚ</h1>
        </div>
    );
}

export default Circulodiv;