import React from 'react'
import '../App.css'

function CocineraSection () {
    return (
<section className="cocinerab">
  <picture data-aos="fade-right" data-aos-duration="800"
                data-aos-offset="500"
                data-aos-easing="ease-in-sine"
                data-aos-mirror="true">
    <img className="chefimg" src="assets/images/Chef.png" alt="Chef.png" />
  </picture>
  <div className="ramen" data-aos="fade-up" data-aos-duration="800"
            data-aos-offset="500"
            data-aos-easing="ease-in-sine"
            data-aos-mirror="true">
    <picture >
      <img
        className="ramenimg"
        src="assets/images/ramen_2.png"
        alt="ramen_2.png"
      />
    </picture>
    <p>
      Su chef <span>Andrea Lizárraga</span> ha logrado en <br /> casi 5 años
      posicionarse en México, con
      <br /> platillos que mantienen las recetas originales
      <br /> de los países orientales y otros que vienen de <br />
      su creación.
    </p>
  </div>
</section>

    );
}

export default CocineraSection;