import React from 'react'
import '../App.css'
function PaperSection () {
    return (
          <section className="papelb" data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="800"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false">
            <picture>
              <img
                className="barco"
                src="assets/images/barco.png"
                alt="barco.png"
                title="barco.png"
              />
            </picture>
            <h2>ACERCA DE NOSOTROS</h2>
            <p>
              Nao Kitchen mantiene una fusión entre la <br />
              cocina oriental con el toque mazatleco.
            </p>
          </section>
    );
}

export default PaperSection;