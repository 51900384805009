import React from 'react'
import NavbarSite from './componet/Navbar';
import PaperSection from './componet/Papel'
import CocineraSection from './componet/Cocinera';
import Circulodiv from './componet/Circulo';
import MenuSection from './componet/Menu';
import FooterSection from './componet/Footer';

export default function Profile() {
  return(
    <>
    <NavbarSite />
    <PaperSection />
    <CocineraSection />
    <Circulodiv />
    <MenuSection />
    <FooterSection />
    </>
  );
}

