import React from "react";
import '../App.css'

function MenuSection () {
    return (
        <div data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="800"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false">
            <section className="menu">
            <div className="comidas">
                <div className="comida">
                <picture>
                    <img src="assets/images/plato1.png" alt="plato1.png" />
                </picture>
                <h4>FILETE SSAMJANG</h4>
                </div>
                <div className="comida">
                <picture>
                    <img src="assets/images/plato2.png" alt="plato2.png" />
                </picture>
                <h4>CEVICHE VERDE</h4>
                </div>
                <div className="comida">
                <picture>
                    <img src="assets/images/plato3.png" alt="plato3.png" />
                </picture>
                <h4>CHURRO HELADO</h4>
                </div>
                <div className="comida">
                <picture>
                    <img src="assets/images/plato4.png" alt="plato4.png" />
                </picture>
                <h4>COSTILLAS COREANAS</h4>
                </div>
            </div>
            <a href="https://nao.proyecta.com.mx/menu.pdf" target="_blank">
                <h2>VER MENÚ COMPLETO</h2>
            </a>
        </section>
    </div>
        
    );
}

export default MenuSection;